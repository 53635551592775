<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-03-11 15:46:37
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-03-14 18:41:33
 * @FilePath: \official_website\src\mobile\pages\products\details\Gemini.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- 吉米娜系列  -->
<template>
  <div id="gemini">
    <Header></Header>
    <div class="gemini-img">
       <Breadcrumb
        :productType="productType"
        :productName="productName"
      ></Breadcrumb>
      <img src="@/assets/image/gimini/gemini.png" alt="" />
      <div class="illustrate-box">
        <div class="title wow animate__animated animate__fadeInUp">
          吉米娜数字切片扫描系列
        </div>
        <div class="line"></div>
        <ul class="keywords wow animate__animated animate__fadeInUp">
          <li>超清</li>
          <li>超快</li>
          <li>灵活</li>
          <li>便捷</li>
          <li>安全</li>
        </ul>
      </div>
    </div>
    <div class="gemini-description">
      <p class="description-text wow animate__animated animate__fadeInUp">
        “吉米娜”数字病理切片扫描分析系统配置了全球顶尖扫描和分析的软硬件系统。该系统能够对细胞、组织等不同病理切片进行数字化扫描和智能化的分析，为病理医生提供了“数智化”的实验室解决方案。同时，通过远程诊断平台，帮助医疗机构实现病理诊断的分级诊疗，促进医联体单位、区域中心等的互联互助，开启病理诊断的“标准化、系统化、数字化、智能化”的新篇章。
      </p>
      <div class="prod-single">
        <div class="title wow animate__animated animate__fadeInUp">超清</div>
        <div class="desc wow animate__animated animate__fadeInUp">
          像元分辨率可达3.45μm
        </div>
        <div class="prod-img">
          <img
            class="wow animate__animated animate__fadeInUp"
            src="@/assets/image/gimini/prod-1.png"
            alt=""
          />
        </div>
      </div>
      <div class="prod-double">
        <div class="title wow animate__animated animate__fadeInUp">超快</div>
        <div class="desc wow animate__animated animate__fadeInUp">
          实时成像，15×15mm片扫描时间小于40秒
        </div>
        <div class="prod-img">
          <div class="img-box">
            <img
              class="wow animate__animated animate__fadeInUp"
              src="@/assets/image/gimini/prod-2.png"
              alt=""
            />
          </div>
          <div class="img-box">
            <img
              class="wow animate__animated animate__fadeInUp"
              src="@/assets/image/gimini/prod-3.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="prod-double">
        <div class="title wow animate__animated animate__fadeInUp">灵活</div>
        <div class="desc wow animate__animated animate__fadeInUp">
          全自动切片区域识别，支持图形、文字、语音等多种标注手段
        </div>
        <div class="prod-img">
          <div class="img-box">
            <img
              class="wow animate__animated animate__fadeInUp"
              src="@/assets/image/gimini/prod-4.png"
              alt=""
            />
          </div>
          <div class="img-box">
            <img
              class="wow animate__animated animate__fadeInUp"
              src="@/assets/image/gimini/prod-5.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="prod-double">
        <div class="title wow animate__animated animate__fadeInUp">便捷</div>
        <div class="desc wow animate__animated animate__fadeInUp">
          任意缩放，多屏分析，同步浏览
        </div>
        <div class="prod-img">
          <div class="img-box">
            <img
              class="wow animate__animated animate__fadeInUp"
              src="@/assets/image/gimini/prod-6.png"
              alt=""
            />
          </div>
          <div class="img-box">
            <img
              class="wow animate__animated animate__fadeInUp"
              src="@/assets/image/gimini/prod-7.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="prod-single">
        <div class="title wow animate__animated animate__fadeInUp">安全</div>
        <div class="desc wow animate__animated animate__fadeInUp">
          切片采用安全Hash算法进行加密，确保数据不外泄
        </div>
        <div class="prod-img">
          <img
            class="wow animate__animated animate__fadeInUp"
            src="@/assets/image/gimini/prod-8.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <RelatedProduct :swiperData="relatProd"></RelatedProduct>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import api from "../../../../api/api";
import Header from "../../../components/Header.vue";
import Footer from "../../../components/Footer.vue";
import Breadcrumb from "../../../components/Breadcrumb.vue";
import RelatedProduct from "../componets/RelatedProduct.vue";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
    Breadcrumb,
    RelatedProduct,
  },
  data() {
    return {
      productType: "产品",
      productName: "吉米娜数字切片扫描系列",
      relatProd: [
        {
          title: "全自动特殊染色机",
          src: require("@/assets/image/product/film-4.png"),
          path: "/product/special",
        },
        {
          title: "DNA定量分析诊断试剂",
          src: require("@/assets/image/product/reagent-2.png"),
          path: "/product/dnaReagent",
        },
        {
          title: "DNA倍体定量分析系统",
          src: require("@/assets/image/product/diagnosis-1.png"),
          path: "/product/dnaProd",
        },
        {
          title: "扫描通量600片",
          src: require("@/assets/image/product/digit-1.png"),
          path: "/product/gemini",
        },
        {
          title: "扫描通量120片",
          src: require("@/assets/image/product/digit-2.png"),
          path: "/product/gemini",
        },
        {
          title: "扫描通量30片",
          src: require("@/assets/image/product/digit-3.png"),
          path: "/product/gemini",
        },
        {
          title: "扫描通量2片",
          src: require("@/assets/image/product/digit-4.png"),
          path: "/product/gemini",
        },
      ],
      urlPath: "", // 当前路由
      allProdList: [], // 所有产品数据
      currentList: {}, // 当前页面数据
    };
  },
  async created() {
    await this.getPordList(1, 100, {});
    await this.getChooseId(this.currentList.id);
  },
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {
    $route: {
      // $route可以用引号，也可以不用引号  监听的对象
      handler(to, from) {
        // console.log(to.path);
        this.urlPath = to.path;
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
    // 获取所有产品信息
    async getPordList(pageIndex, pageSize, params) {
      try {
        var res = await api.getPorductList(pageIndex, pageSize, params);
        if (res && res.data && res.data.code === 200) {
          this.allProdList = res.data.models;
          let presentProd = this.allProdList.filter((item) => {
            return item.summary == this.urlPath;
          });
          this.currentList = presentProd.length != 0 ? presentProd[0] : 0;
        } else {
        }
      } catch (error) {}
    },
    // 获取相关产品信息
    async getChooseId(prodId) {
      try {
        var res = await api.getProdRelationByid(prodId);
        if (res && res.data && res.data.code === 200) {
          if (res.data.models) {
            let result = res.data.models[0].relationID;
            if (result) {
              let relationArr = result.split(",");
              let tepmArr = [];
              relationArr.forEach((item) => {
                tepmArr.push(Number(item));
              });

              let relatedList = [];
              tepmArr.forEach((items) => {
                let secData = this.allProdList.filter((item) => {
                  return item.id == items;
                });
                if (secData.length != 0) {
                  relatedList.push(secData[0]);
                }
              });

              this.relatProd = relatedList;

              console.log(this.relatProd);
            } else {
            }
          }
        } else {
        }
      } catch (error) {}
    },
  },
};
</script>
    
<style lang="scss">
#gemini {
  padding-top: 6.5rem;
  .gemini-img {
    img {
      width: 100%;
    }
    .illustrate-box {
      padding: 1.25rem;
      .title {
        font-size: 1.5rem;
        font-family: "SourceHanSansSC-Medium";
        margin-bottom: 1.5rem;
      }
      .line {
        width: 4.25rem;
        height: 3px;
        margin-bottom: 1.25rem;
        background: #177a73;
      }
      .keywords {
        display: flex;
        li {
          width: 20%;
          height: 2rem;
          line-height: 2rem;
          margin-right: 4%;
          color: #fff;
          background: #177a73;
          border-radius: 2rem;
          text-align: center;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }
  .gemini-description {
    padding: 1.25rem;
    width: 100%;
    box-sizing: border-box;
    background-size: cover;
    .description-text {
      font-size: 1rem;
      line-height: 2rem;
      margin-bottom: 2rem;
      font-family: "OPlusSans3-Regular";
    }
    .prod-single {
      margin-bottom: 2.8rem;
      .title {
        font-size: 1.2rem;
        font-family: "SourceHanSansSC-Medium";
        margin-bottom: 1rem;
      }
      .desc {
        font-size: 1rem;
        font-family: "OPlusSans3-Regular";
        margin-bottom: 1.5rem;
      }
      .prod-img {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    .prod-double {
      margin-bottom: 2.8rem;
      .title {
        font-size: 1.2rem;
        font-family: "SourceHanSansSC-Medium";
        margin-bottom: 1rem;
      }
      .desc {
        font-size: 1rem;
        font-family: "OPlusSans3-Regular";
        margin-bottom: 1.5rem;
      }
      .prod-img {
        // display: flex;
        .img-box {
          width: 100%;
          &:first-child {
            margin-bottom: 1rem;
          }
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .footer {
    background: #f5f5f5;
  }
}
</style>